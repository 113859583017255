.uploadPicturesWrapper {
       display: flex !important;
       justify-content: space-between;
       flex-wrap: wrap;
     }
     
     .uploadPictureContainer {
       width: 100px !important;
       height: 100px !important;
       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
       margin: 10px;
       position: relative;
       margin: 8px !important;
     }
     
     .deleteImage {
       top: 10px;
       right: 10px;
       color: red;
     }
     