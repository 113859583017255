.MuiTab-textColorSecondary{
    color: #ffff ;
}

.MuiTabs-indicator{
    background-color: #4984BF !important;    
}
.css-1ujnqem-MuiTabs-root  .MuiTab-textColorSecondary{
color: #038dd7 !important;
}
.css-tkn350-MuiTabs-root  .MuiTab-textColorSecondary{
    color: #038dd7 !important;
    }
.MuiTypography-body1  .css-hrqm3n-MuiCardHeader-root{
    background-color:#038dd7 !important
}
.MuiCardHeader-title{
    color: #fff !important;
}
.MuiSvgIcon-fontSizeMedium{
    color: #4984BF !important;
    cursor: pointer;
}
.MuiTableCell-head{ 
    background-color: #3255a3 ;
    color: #fff ;
}
.edit-customer {
    color:#fff !important;
}
.MuiTypography-body1 .MuiPaper-elevation1 .css-1r2et7f-MuiTableRow-root .MuiTableCell-head {
    background-color: #038dd7 !important;
color: #fff !important;
}
.css-1pz7awu-MuiButtonBase-root-MuiIconButton-root .MuiSvgIcon-fontSizeMedium{

color: #fff !important;
}

.MuiBreadcrumbs-li a{
    color: #038dd7;
    font-weight: 500;
    font-size: 15px;
}
.header-personal-details .MuiCardHeader-subheader{
    color: #fff;
}

.confirm-class .css-1s305u7-MuiPaper-root-MuiDialog-paper{
    width: 28% !important;
    height: 28% !important;
}
.css-1s305u7-MuiPaper-root-MuiDialog-paper{
    width: 28% !important;
    height: 28% !important;
}
.css-1dxt1fx-MuiTableCell-root.MuiTableCell-body{
    width: 20% !important;
}
.css-1gluf32-MuiTableCell-root{
    padding: 6px 3px !important;
}
.css-bdgugt.Mui-selected{
    color: #038dd7 !important;
}
.MuiTabs-scroller .css-bdgugt{
    color: #038dd7 !important;
}

.step-class{
    border: 1px solid #4984bf;
    background: #4984bf!important;
    text-align: center;
    color: #fff;
    /* font-weight: 700; */
    border-radius: 31px;
    font-size: 13px;
    border-width: 3px 6px 4px 7px;
}


.step-title{
    margin-left: 9px;
    color: rgb(23, 43, 77);
    font-weight: 500;
}

.css-38go1i{
    width: 20% !important;
}
.css-1g5s2q{
    padding: 6px 3px !important;  
}

.css-19alodt-MuiFormHelperText-root{
    margin-left:0px !important
}

.css-1qw96cp{
    padding: 0px !important;
}

.css-19kzrtu{
    padding: 0px !important;
}

.css-we0np1{
    width: 100% !important;
}
.miui-icon-peronal{
    font-size:44px;
    color:#fff !important
}
.header-personal-details .MuiCardHeader-title{
    font-size: 16px !important;
    font-weight: 500;
}

.custom-segmented .ant-segmented-item-selected {
    background-color: #ff9700 !important; /* Selected segment background */
    color: white !important; /* Selected text color */
    font-size: 14px;
  font-weight: 500;
  }
