.first_class{
    align-items: center !important;
}

.customer-image-upload .ant-upload{
padding: none !important;
}
.customer-image-upload{
    width: 180px !important;
    height: 180px !important; 
}
.customer-image-upload.ant-upload{
    display: flex;
    align-items: center;
    justify-content: center;
}

.customer-image-upload .ant-upload-btn{
    padding: 0px !important;
    border: none !important;
    width: 180px;
    height: 180px;
}

.customer-image-upload .ant-upload-drag{
    border: none !important;
    width: 180px;
    height: 180px;
}