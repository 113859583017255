.ant-modal-close-x{
    margin-top: -15px !important;
    margin-right: -17px !important;
    font-size: 23px !important;
    font-weight: bold !important;
    color: #000 !important;
}
.css-1uq6l3d-MuiInputBase-root-MuiOutlinedInput-root{
    height: 42px !important;

    
}

.css-1y9gz45-MuiFormLabel-root-MuiInputLabel-root{
    margin-top: -3px !important;
}
.fileContainer{
    background: transparent !important;
    box-shadow: none !important;
    margin-top: -21px !important;    
     padding: none !important; 
     margin: none !important;
}
.add_proof_icon  svg{
    margin-top: 7px ;
    width: 70px !important;
    height: 35px !important;
}

.proof_list{
    margin-bottom: 24px;
    color: #172b4d;
    font-weight: 500;
    font-size: 0.875rem;
    margin-top: 24px;
}

.comman_value_style{
font-weight: 500;
    font-size: 16px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.comman_title_style{
    font-weight: 400;
    font-size: 13px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}